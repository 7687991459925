<template>
  <main>
    <SectionHero :variant="hero.variant">
      <OrganismHero :hero="hero" />
      <template #footerShort>
        <div class="flex flex-col flex-1 gap-y-3 md:flex-row gap-x-[10px] justify-between">
          <div class="flex flex-col flex-1 gap-y-3 md:flex-row gap-x-[10px] md:items-end -md:order-2">
            <div>
              <AtomButtonDropdown :items="direction" />
            </div>
            <div>
              <AtomButtonDropdown :items="clubItems" />
            </div>
          </div>
          <AtomButton class="self-end -md:order-1 -md:w-full" to="/timetable">РАСПИСАНИЕ</AtomButton>
        </div>
      </template>
    </SectionHero>
    <SectionDefault>
      <Accordion />
      <Accordion2 />
      <Builder :blocks="blocks" />
    </SectionDefault>
  </main>
</template>

<script>
import AtomButton from '../../components/atoms/AtomButton'
import AtomButtonDropdown from '../../components/atoms/AtomButtonDropdown'
import Builder from '../../components/builder/Builder'
import pageBuilder from '../../components/mixins/pageBuilder.js'
import OrganismHero from '../../components/organisms/layouts/OrganismHero'
import Accordion from '../../components/templates/fitness/kids-clubs/Accordion'
import Accordion2 from '../../components/templates/fitness/kids-clubs/Accordion2'
import SectionDefault from '../../components/templates/layouts/SectionDefault'
import SectionHero from '../../components/templates/layouts/SectionHero'

const mixinPageBuilder = pageBuilder({
  pageUrl: 'kids-clubs',
})

export default {
  name: 'FitnessKids',
  components: {
    Builder,
    AtomButton,
    SectionDefault,
    SectionHero,
    OrganismHero,
    AtomButtonDropdown,
    Accordion,
    Accordion2,
  },
  mixins: [mixinPageBuilder],
  meta: {
    theme: 'dark',
  },
  data: c => {
    return {
      hero: {
        variant: 'dark-parallax',
        title1: 'Детские',
        title2: 'клубы',
        image: 'fitness/kids-clubs/hero.webp',
        imageWrap: 'img-border-0',
        imageMobile: 'fitness/kids-clubs/hero-mobile.webp',
        breadcrumbs: c.$breadcrumbs.kidsClubs(),
      },
      direction: [{ text: 'Москва' }, { text: 'Москва 2' }],
      clubItems: [{ text: 'ENCORE СИТИ' }, { text: 'ENCORE СИТИ 2' }],
    }
  },
}
</script>
