<template>
  <div
    ref="root"
    class="item py-3 px-2 md:py-6 md:px-12 cursor-pointer relative flex items-center"
    @mouseover="reposition"
    @mouseleave="isOver = false"
    @mousemove="reposition"
  >
    <AtomTitle class="title" tag="h2" class-name="!py-[0.08em]" :sup="list.labelIcon">{{ list.label }}</AtomTitle>
    <AtomImage
      class="-md:hidden image aspect-[277/318] max-w-[277px] absolute object-cover right-0 z-10"
      :style="`transform: translate(${x}px, ${y}px)`"
      :class="{ 'with-transition': withTransition, 'opacity-100': isOver, 'opacity-0': !isOver }"
      :src="list.image"
    />
  </div>
</template>

<script>
import AtomImage from '../../atoms/AtomImage'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'MoleculeListTitleImage',
  components: {
    AtomTitle,
    AtomImage,
  },
  props: ['list'],
  data() {
    return {
      isOver: false,
      withTransition: false,
      x: 0,
      y: 0,
    }
  },
  watch: {
    isOver() {
      if (this.isOver) {
        setTimeout(() => (this.withTransition = this.isOver))
      } else {
        this.withTransition = false
      }
    },
  },
  methods: {
    reposition({ x, y }) {
      this.isOver = true
      const rect = this.$refs.root.getBoundingClientRect()

      this.x = getAxisValue(x - rect.x, rect.width, rect.width / 10)
      this.y = getAxisValue(y - rect.y, rect.height, rect.height)

      function getAxisValue(axisValue, dimensionValue, imageAreaValue) {
        const percent = Math.max(0, Math.min(100, (axisValue * 100) / dimensionValue))
        return (-imageAreaValue * (100 - percent)) / 100
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.item {
  @apply border-t border-gray-500;
  transition: color 0.3s var(--cubic-bezier);
  .image {
    transition: opacity 0.5s var(--cubic-bezier);
    pointer-events: none;
    &.with-transition {
      transition: opacity 0.5s var(--cubic-bezier), transform 1.5s var(--cubic-bezier);
    }
  }
  .title {
    transition: transform 0.3s var(--cubic-bezier);
    ::v-deep svg {
      @apply -md:h-3 -md:w-3;
    }
  }
  &:hover {
    @apply !text-gray-100;
    ::v-deep path {
      @apply !stroke-gray-100;
    }
    .title {
      @apply md:translate-x-8;
    }
  }
}
</style>
