<template>
  <div class="md:mb-48">
    <AtomContainer>
      <div class="md:grid grid-cols-10 gap-x-5 border-b border-gray-500 py-3 md:py-5">
        <AtomTitle v-if="wrap.title" tag="h3" :content="wrap.title" class="col-span-4" />
        <AtomText v-if="wrap.text" :content="wrap.text" class="col-span-6 -md:pt-2" />
      </div>
      <Accordion>
        <AccordionItem v-for="item in list" :key="item.name" :item="item" class="-md:border-gray-100">
          <template slot="accordion-trigger">
            <AtomTitle
              class="pt-6 overflow-visible"
              tag="h1"
              class-name="-md:flex overflow-visible md:pt-[1vw] md:!pb-1"
              :sup="item.sup"
              class-name-sup="md:!text-base md:!top-2 -md:!left-2 md:!left-0 -md:leading-[130%] -md:!top-0"
            >
              <template v-if="item.title">{{ item.title }}</template>
              <template v-if="item.title1">
                <span class="relative"
                  >{{ item.title1
                  }}<sup
                    class="absolute tracking-tight md:tracking-tighter text-[12px] md:text-base !left-[calc(100%_+_4px)] md:!left-[calc(100%_+_12px)]"
                    >{{ item.sup1 }}</sup
                  ></span
                >
                <span class="relative"
                  >{{ item.title2
                  }}<sup
                    class="absolute tracking-tight md:tracking-tighter text-[12px] md:text-base !left-[calc(100%_+_4px)] md:!left-[calc(100%_+_12px)]"
                    >{{ item.sup2 }}</sup
                  ></span
                >
              </template>
            </AtomTitle>
          </template>
          <template slot="accordion-content">
            <div class="lists">
              <div v-for="listItem in item.list" :key="listItem.name" class="w-full" @click="modalItem = listItem">
                <MoleculeListTitleImage :list="listItem" />
              </div>
            </div>
          </template>
        </AccordionItem>
      </Accordion>
    </AtomContainer>
    <Modal v-if="modalItem" :title="modalItem.label" :img="modalItem.image" @close="modalItem = null">
      <div class="flex flex-col gap-y-10 mb-10">
        <MoleculeModalRowButton v-for="item in modalItem.tags" :key="item.name" :item="item" />
      </div>
      <MoleculeModalRowText :item="modalItem.text" />
      <MoleculeModalRowProgress v-for="item in modalItem.progress" :key="item.name" :item="item" />
      <template #footer>
        <MoleculeModalRowButton v-for="item in modalItem.footer" :key="item.name" :item="item" />
      </template>
    </Modal>
  </div>
</template>

<script>
import Accordion from '../../../atoms/accordion/AtomAccordion'
import AccordionItem from '../../../atoms/accordion/AtomAccordionItem'
import AtomText from '../../../atoms/AtomText'
import AtomTitle from '../../../atoms/AtomTitle'
import AtomContainer from '../../../atoms/layouts/AtomContainer'
import MoleculeListTitleImage from '../../../molecules/layouts/MoleculeListTitleImage'
import MoleculeModalRowButton from '../../../molecules/modals/MoleculeModalRowButton'
import MoleculeModalRowProgress from '../../../molecules/modals/MoleculeModalRowProgress'
import MoleculeModalRowText from '../../../molecules/modals/MoleculeModalRowText'
import Modal from '../../../templates/layouts/Modal'

export default {
  name: 'OrganismAccordion',
  components: {
    MoleculeModalRowProgress,
    MoleculeModalRowButton,
    MoleculeModalRowText,
    AtomContainer,
    AtomTitle,
    AtomText,
    Accordion,
    AccordionItem,
    MoleculeListTitleImage,
    Modal,
  },
  props: ['list', 'wrap'],
  data() {
    return {
      modalItem: null,
    }
  },
}
</script>

<style lang="postcss" scoped>
.lists {
  &:hover {
    .item {
      @apply md:text-gray-500;
      ::v-deep path {
        @apply stroke-gray-500;
      }
    }
  }
}
</style>
