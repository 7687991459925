<template>
  <OrganismAccordion :list="data" :wrap="wrap" />
</template>

<script>
import OrganismAccordion from '../../../organisms/fitness/kids-clubs/OrganismAccordion'

export default {
  name: 'TemplateAccordion',
  components: {
    OrganismAccordion,
  },
  data: () => {
    return {
      wrap: {
        title: 'Секции для детей',
        text: 'Наши тренеры помогут ребёнку выбрать тот вид спортивной или творческой активности, который максимально раскроет его таланты и&nbsp;личные качества. Cекция подразумевает посещение на&nbsp;постоянной основе, 3&nbsp;раза в&nbsp;неделю.',
      },
      data: [
        {
          title1: '14',
          sup1: 'мес.',
          title2: ' – 3',
          sup2: 'года',
          list: [
            {
              label: 'Outdoor Functional Лужники',
              image: 'fitness/mini-group/features/1.webp',
              progress: [
                {
                  title: 'Сила',
                  progress: 50,
                },
                {
                  title: 'Гибкость',
                  progress: 20,
                },
                {
                  title: 'Восстановление',
                  progress: 90,
                },
              ],
              text: {
                title: 'О программе',
                text: 'Тренировка на степ-платформе проходит с использованием дополнительного оборудования. Занятия сочетают в себе силовую и аэробную нагрузку. Это отличная комбинация для того чтобы придать телу рельеф, сжечь калории, укрепить мышцы и развить выносливость.',
              },
              footer: [
                {
                  title: 'Расписание в клубах',
                  controls: [
                    {
                      title: 'Encore Сити',
                      to: '/',
                    },
                    {
                      title: 'Encore Ясенево',
                      to: '/',
                    },
                    {
                      title: 'Encore Ходынка',
                      to: '/',
                    },
                  ],
                },
              ],
              tags: [
                {
                  title: 'Направления',
                  controls: [
                    {
                      title: 'Групповые тренировки',
                      to: '/',
                    },
                    {
                      title: 'Power',
                      to: '/',
                    },
                  ],
                },
                {
                  title: 'Тренер',
                  controls: [
                    {
                      title: 'Дмитрий Братушев',
                      to: '/',
                    },
                    {
                      title: 'Екатерина Сыромятникова',
                      to: '/',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Aikido',
              image: 'fitness/mini-group/features/1.webp',
            },
            {
              label: 'Всем шпагат',
              labelIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984" stroke="#282929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 6V18" stroke="#282929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#282929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`,
              image: 'fitness/mini-group/features/1.webp',
            },
            {
              label: 'Aikido',
              image: 'fitness/mini-group/features/1.webp',
            },
            {
              label: 'Aikido',
              image: 'fitness/mini-group/features/1.webp',
            },
          ],
        },
        {
          title: '2 – 3',
          sup: 'года',
          list: [
            {
              label: 'Butt Workout',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'Core Blend',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'BarreWorkout',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'TRX Athletic',
              image: 'fitness/group-training/features/1.webp',
            },
          ],
        },
        {
          title: '4 – 6',
          sup: 'лет',
          list: [
            {
              label: 'Butt Workout',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'Core Blend',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'BarreWorkout',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'TRX Athletic',
              image: 'fitness/group-training/features/1.webp',
            },
          ],
        },
      ],
    }
  },
}
</script>
