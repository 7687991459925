<template>
  <OrganismAccordion :list="data" :wrap="wrap" />
</template>

<script>
import OrganismAccordion from '../../../organisms/fitness/kids-clubs/OrganismAccordion'

export default {
  name: 'TemplateAccordion',
  components: {
    OrganismAccordion,
  },
  data: () => {
    return {
      wrap: {
        title: 'Групповые занятия для детей',
      },
      data: [
        {
          title1: '14',
          sup1: 'мес.',
          title2: ' – 3',
          sup2: 'года',
          list: [
            {
              label: 'Здоровые ножки',
              image: 'fitness/kids-clubs/modal.webp',
              progress: [
                {
                  title: 'Сила',
                  progress: 50,
                },
                {
                  title: 'Гибкость',
                  progress: 20,
                },
                {
                  title: 'Восстановление',
                  progress: 90,
                },
              ],
              text: {
                title: 'О программе',
                text: 'Корригирующая тренировка с активным участием родителей направлена на формирование правильных сводов стопы малыша. Тренировка проходит с использованием специального массажного оборудования, что способствует профилактике плоскостопия.',
              },
              footer: [
                {
                  title: 'Расписание в клубах',
                  controls: [
                    {
                      title: 'Encore Сити',
                      to: '/',
                    },
                    {
                      title: 'Encore Ясенево',
                      to: '/',
                    },
                    {
                      title: 'Encore Ходынка',
                      to: '/',
                    },
                  ],
                },
              ],
              tags: [
                {
                  title: 'Направления',
                  controls: [
                    {
                      title: '14 мес. - 3 года',
                      to: '/',
                    },
                  ],
                },
                {
                  title: 'Тренер',
                  controls: [
                    {
                      title: 'Дмитрий Братушев',
                      to: '/',
                    },
                    {
                      title: 'Екатерина Сыромятникова',
                      to: '/',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Фитнес-малыш',
              image: 'fitness/mini-group/features/1.webp',
            },
            {
              label: 'Ритмика',
              image: 'fitness/mini-group/features/1.webp',
            },
            {
              label: 'Умничка',
              image: 'fitness/mini-group/features/1.webp',
            },
          ],
        },
        {
          title: '2 – 3',
          sup: 'года',
          list: [
            {
              label: 'Butt Workout',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'Core Blend',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'BarreWorkout',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'TRX Athletic',
              image: 'fitness/group-training/features/1.webp',
            },
          ],
        },
        {
          title: '4 – 6',
          sup: 'лет',
          list: [
            {
              label: 'Butt Workout',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'Core Blend',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'BarreWorkout',
              image: 'fitness/group-training/features/1.webp',
            },
            {
              label: 'TRX Athletic',
              image: 'fitness/group-training/features/1.webp',
            },
          ],
        },
      ],
    }
  },
}
</script>
